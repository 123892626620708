import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import ButtonMailto from '../components/button-mail-to'
export default function Contacts() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt:2}}>
      <Card sx={{ maxWidth: 345 }}>
      <Box sx={{m:2}}>
        <CardMedia
          component="img"
          alt="Robotiem logo"
          image="/images/Robotiem_logo.png"
        />
        </Box>
        <CardContent>
          <Box sx={{display: "flex",flexDirection: 'column', justifyContent: "center", alignItems: 'center'}}>
            <Typography gutterBottom variant="h5" component="div">
                Rekvizīti
            </Typography>
            <Typography variant="body2" color="text.secondary">
                <strong>Nosaukums:</strong> SIA "Robotiem"<br/>
                <strong>Reģistrācijas numurs:</strong> 40103632832<br/>
                <strong>PVN maksātāja numurs:</strong> LV40103632832<br/>
                <strong>Juridiskā adrese:</strong> Puškina 8-1, Valka, LV-4701<br/>
                <strong>Banka:</strong> AS “Swedbank”<br/>
                <strong>SWIFT:</strong> HABALV22<br/>
                <strong>IBAN:</strong> LV45HABA0551037612606<br/>
                <strong>Telefons:</strong> 25511555<br/>
                <strong>E-pasts:</strong> <ButtonMailto label="robotiem@gmail.com" mailto="mailto:robotiem@gmail.com" /><br/>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

