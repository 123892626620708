import { Container } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router'
import ScrollableTabs from '../components/scrollable-tabs'
import Preschool from './lego_subpages/preschool'
import Primary from './lego_subpages/primary'
import Secondary from './lego_subpages/secondary'

function Lego() {
    const [tabVal, setTabVal] = React.useState(false)
    let {subpage} = useParams()
    React.useEffect(() => {
        setTabVal(subpage?subpage:false)
    }, [subpage])

    return (
      <>
        <ScrollableTabs tabVal={tabVal} setTabVal={setTabVal}/>
        <Container maxWidth="md">
            {subpage==="preschool" && <Preschool />}
            {subpage==="primary" && <Primary />}
            {subpage==="secondary" && <Secondary />}
        </Container>
      </>
    );
}

export default Lego
