export const products=[
    {
        number: 45678,
        title:'Spike Prime pamatkomplekts',
        price: 392.00,
        images:[
            '/images/secondary/45678.jpg',
            '/images/secondary/45678_2.jpg',
            '/images/secondary/45678_3.jpg',
            '/images/secondary/45678_4.jpg',
            '/images/secondary/45678_5.jpg',
            '/images/secondary/45678_6.jpg',
            '/images/secondary/45678_7.jpg',
            '/images/secondary/45678_8.jpg',
        ],
        description:''
    },
    {
        number: 45681,
        title:'SPIKE Prime papildkomplekts v2',
        price: 122.40,
        images:[
            '/images/secondary/45681.jpg',
        ],
        description:''
    },
    {
        number: 2000719,
        title:'Spike Prime rezerves detaļu komplekts',
        price: 6.00,
        images:[
            '/images/secondary/2000719.jpg',
        ],
        description:''
    },
    {
        number: 45611,
        title:'Spike Prime USB kabelis',
        price: 10.80,
        images:[
            '/images/secondary/45611.jpg',
        ],
        description:''
    },
    {
        number: 45610,
        title:'Spike Prime spēka senors',
        price: 22.80,
        images:[
            '/images/secondary/45610.jpg',
        ],
        description:''
    },
    {
        number: 45605,
        title:'Spike Prime krāsu sensors',
        price: 26.40,
        images:[
            '/images/secondary/45605.jpg',
        ],
        description:''
    },
    {
        number: 45604,
        title:'Spike Prime attāluma sensors',
        price: 39.60,
        images:[
            '/images/secondary/45604.jpg',
        ],
        description:''
    },
    {
        number: 45603,
        title:'Spike Prime vidējais motors',
        price: 46.80,
        images:[
            '/images/secondary/45603.jpg',
        ],
        description:''
    },
    {
        number: 45602,
        title:'Spike Prime lielais motors',
        price: 48.00,
        images:[
            '/images/secondary/45602.jpg',
        ],
        description:''
    },
    {
        number: 45601,
        title:'Spike Prime kontrolieris',
        price: 288.00,
        images:[
            '/images/secondary/45601.jpg',
        ],
        description:''
    },
    
]