import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router';

export default function ScrollableTabs({tabVal, setTabVal}) {

  let navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', bgcolor: 'background.paper' }}>
      <Tabs
        value={tabVal?tabVal:false}
        onChange={(o,v)=>setTabVal(v)}
        variant="scrollable"
        scrollButtons="auto"
      >
		{/*<Tab value='preschool' label="Pirmskola" onClick={()=>navigate('/lego/preschool')}/>*/}
        <Tab value='primary' label="Pamatskola" onClick={()=>navigate('/lego/primary')}/>
        <Tab value='secondary' label="Vidusskola" onClick={()=>navigate('/lego/secondary')}/>
        {/*<Tab value='general' label="Vispārīgi" onClick={()=>navigate('/lego/general')}/>*/}
      </Tabs>
    </Box>
  );
}
