import { createContext } from "react";
import lvLocale from "date-fns/locale/lv";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";

const localeMap = {
  lv: lvLocale,
  en: enLocale,
  ru: ruLocale,
};

const LanguageContext = createContext("lv");

export { LanguageContext, localeMap };
