import React, { useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
//import { AuthProvider } from "./auth-context";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { LanguageContext, localeMap } from "./language-context";

const queryClient = new QueryClient();



function AppProviders({ children }) {
  const [locale, setLocale] = React.useState("lv");
  const language = useMemo(() => ({ locale, setLocale }), [locale, setLocale]);
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#000000',
        },
        secondary: {
          main: '#f9a11c',
        },
      },
    }
  );



  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Router>
          <LanguageContext.Provider value={language}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
              {/*<AuthProvider>*/}
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
              {/*</AuthProvider>*/}
            </LocalizationProvider>
          </LanguageContext.Provider>
        </Router>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export { AppProviders };
