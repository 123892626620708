import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { products } from '../data';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';



function Product({productCode, setProductCode}) {
    const [data, setData] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)

    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

  
    const handleClose = () => {
        setProductCode(null);
    };

    React.useEffect(() => {
        if(productCode){
          setIsLoading(true)
            let res=products.find(x => x.number === parseInt(productCode))
            if (res) setData(res)
            else {
                setProductCode(null)
                setData(null);
            }
            setIsLoading(false)
        }
    }, [productCode,setProductCode])
  
    return (
      <>
        {data && (
          <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={productCode && data ? true : false}
            onClose={handleClose}
          >
            {isLoading ? (
              <DialogContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </DialogContent>
            ) : (
              <>
                <DialogTitle>{data.title}</DialogTitle>
                <DialogContent>
                  <DialogContentText>

                  <img src={data.images[selectedTab]} srcSet={data.images[selectedTab]} alt="image0" loading="lazy" />

                  <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="scrollable force tabs example">
                  {data.images.map((item, index) => (
                      <Tab sx={{minWidth:30, px:0.5}} key={index} value={index} icon={<Box component="img" height={40} src={item} srcSet={item} />} />
                    ))}
                  </Tabs>

                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        )}
      </>
    );
}

export default Product
