import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea } from '@mui/material';

export default function RaisableCard({item, setProductCode}){
    const [raised, setRaised] = React.useState(false)
    return(
        <Card raised={raised} onMouseOver={()=>setRaised(true)} onMouseLeave={()=>setRaised(false)} >
            <CardActionArea component={Button} onClick={()=>setProductCode(item.number)}>
                <Box sx={{m:2}}>
                    <CardMedia
                    component="img"
                    alt={item.name}
                    image={item.img}
                    height={150}
                    />
                </Box>
                <CardContent>
                <Box sx={{display: "flex",flexDirection: 'row', alignItems: 'center'}}>
                    <Typography variant="body" align="left">Nr. {item.number}</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="body" align="right"><strong>{item.price} EUR</strong></Typography>
                </Box>
                <Box sx={{mt:1}}>
                    <Typography variant="caption" gutterBottom component="div">
                        <strong>{item.name}</strong>
                    </Typography>
                </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}