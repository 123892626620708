import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RaisableCard from '../../components/risable-card';
import Product from '../product';

const data =[
    {
        img:'/images/secondary/45345.jpg',
        number: '45345',
        name: 'Spike Essential bāzes komplekts',
        price: 335.00
    },
    {
        img:'/images/secondary/45681.jpg',
        number: '45681',
        name: 'SPIKE Prime papildkomplekts v2',
        price: 130.00
    },
    {
        img:'/images/secondary/45678.jpg',
        number: '45678',
        name: 'Spike Prime pamatkomplekts',
        price: 409.00
    },
    {
        img:'/images/secondary/2000719.jpg',
        number: '2000719',
        name: 'Spike detaļu komplekts',
        price: 8.00
    },
    {
        img:'/images/secondary/45611.jpg',
        number: '45611',
        name: 'Spike Prime USB kabelis',
        price: 18.00
    },
    {
        img:'/images/secondary/45610.jpg',
        number: '45610',
        name: 'Spike Prime akumulators',
        price: 98.00
    },
    {
        img:'/images/secondary/45606.jpg',
        number: '45606',
        name: 'Spike Prime spēka senors',
        price: 35.00
    },
    {
        img:'/images/secondary/45605.jpg',
        number: '45605',
        name: 'Spike Prime krāsu sensors',
        price: 44.00
    },
    {
        img:'/images/secondary/45604.jpg',
        number: '45604',
        name: 'Spike Prime attāluma sensors',
        price: 57.00
    },
    {
        img:'/images/secondary/45603.jpg',
        number: '45603',
        name: 'Spike Prime vidējais motors',
        price: 65.00
    },
    {
        img:'/images/secondary/45602.jpg',
        number: '45602',
        name: 'Spike Prime lielais motors',
        price: 73.00
    },
    {
        img:'/images/secondary/45601.jpg',
        number: '45601',
        name: 'Spike Prime kontrolieris',
        price: 383.00
    }
]




export default function Secondary() {
  const [productCode, setProductCode] = React.useState(null);

  return (
    <Box sx={{ flexGrow: 1, mt:2 }}>
      <Grid container spacing={3}>
        {data.map((item,idx)=>(
            <Grid key={idx} item xs={12} sm={6} md={4}>
                <RaisableCard item={item} setProductCode={setProductCode}/>
            </Grid>
        ))}
      </Grid>
      <Product productCode={productCode} setProductCode={setProductCode}/>
    </Box>
  );
}
