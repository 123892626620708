import * as React from 'react';
import Box from '@mui/material/Box';
import { Container} from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';






export default function Projects() {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: '100%'
          },
        }}
      >
        <Card raised={true}>
          <CardHeader
            title="Erasmus+ projekts “Python and Java for Teachers”"
            subheader="01.09.2020 līdz 28.02.2023"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
                <strong>Projekta numurs:</strong> 2020-1-UK01-KA201-079077
            </Typography>
            <Typography variant="body2" color="text.secondary">
                <strong>Projekta nosaukums:</strong> Python and Java for Teachers
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Projekta apraksts:</strong> Projekta mērķis ir apmācīt
              Python programmēšanas skolotājus gan no Eiropas pamatskolām, gan
              vidusskolām un nodrošināt resursus, lai viņi varētu domāt kā
              programmētājs un padarīt datorzinātni interesantu mazajiem
              skolēniem. Kursa laikā skolotāji arī apgūs, kā iesaistīt skolēnus
              ar jaunām tehnoloģijām un projektiem un kā attīstīties ar tādām
              jaunām tehnoloģijām kā AI (mākslīgais intelekts), viņiem
              progresējot.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt:2,
                height:70
              }}
            >
              <img src="images/eu_flag_co_funded_pos_rgb_right-e1571777595813.jpg" alt="" />
              <img src="images/EU-flag-Erasmus_vect_POS.jpg" alt="" />
            </Box>
          </CardContent>
        </Card>

        <Card raised={true}>
          <CardHeader
            title="Erasmus+ projekts “Ready-Set-Robot”"
            subheader="01.10.2018 līdz 30.09.2020"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
                <strong>Projekta numurs:</strong> 2018-1-EE01-KA201-047128
            </Typography>
            <Typography variant="body2" color="text.secondary">
                <strong>Projekta nosaukums:</strong> Ready-Set-Robot (RSR)
            </Typography>
            <Typography variant="body2" color="text.secondary">
                <strong>Mājas lapa:</strong> <a href="https://readysetrobot.eu/">https://readysetrobot.eu/</a>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt:2,
                height:70
              }}
            >
              <img src="images/eu_flag_co_funded_pos_rgb_right-e1571777595813.jpg" alt="" />
              <img src="images/EU-flag-Erasmus_vect_POS.jpg" alt="" />
            </Box>
          </CardContent>
        </Card>




      </Box>
    </Container>
  );
}