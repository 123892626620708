import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";

export default function Preschool() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        "& > :not(style)": {
          mt: 2,
          width: "100%",
        },
      }}
    >
      <Card raised={true}>
        <Box sx={{display: "flex",flexDirection: 'column', justifyContent: "center", alignItems: 'center'}}>
            <CardHeader title="Izstrāde"/>
            <CardContent>
            <Typography variant="body2" color="text.secondary">
                Mājas lapai notiek pārbūves darbi, un šī sadaļa vēl top
            </Typography>
            </CardContent>
        </Box>
      </Card>
    </Box>
  );
}