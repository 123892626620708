import React from 'react'
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { /*AdminRoutes, UserRoutes, OtherRoutes,*/ UnauthenticatedRoutes } from './routes';
// const {AdminRoutes} = React.lazy(() => import('./routes'))
// const {UserRoutes} = React.lazy(() => import('./routes'))
// const {UnauthenticatedRoutes} = React.lazy(() => import('./routes'))
// const {OtherRoutes} = React.lazy(() => import('./routes'))

//import {useAuth} from './context/auth-context'

import AppBar from './components/app-bar'


function App() {

  // let {user} = useAuth();
  // //console.log('user: ', user)

  // let component;
  // if (user && user.type) {
  //   if (user.type === 'admin') {
  //     component = <AdminRoutes/>;
  //   } else if (user.type === 'user') {
  //     component = <UserRoutes/>;
  //   } else {
  //     component = <OtherRoutes/>
  //   }
  // } else {
  //   component = <UnauthenticatedRoutes />;
  // }

  let component = <UnauthenticatedRoutes />;

  return (
    <React.Suspense fallback={<h1>Lādējas...</h1>}>
      <AppBar />
      {component}
    </React.Suspense>
  );
}

export default App;
