import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'

import { NotFound } from './screens/not-found'
//import Home from './screens/home'
import Lego from './screens/lego'
import Projects from './screens/projects'
import Contacts from './screens/contacts'



/*
function AdminRoutes() {

  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}


function UserRoutes() {

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to='/view-history' />} />
      <Route exact path="/login" element={<Login />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

function OtherRoutes() {

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/login" element={<Login />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
*/

function UnauthenticatedRoutes() {

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to='/lego/secondary' />} />
      <Route path="/notfound" element={<NotFound/>} />
      <Route path="/lego/:subpage" element={<Lego/>} />
      <Route path="/lego" element={<Lego/>} />
      <Route path="/projects" element={<Projects/>} />
      <Route path="/contacts" element={<Contacts/>} />
      <Route path="*" element={<NotFound/>} />
    </Routes>
  )
}




export {/*AdminRoutes, UserRoutes, OtherRoutes,*/ UnauthenticatedRoutes}
