import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import MoreIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useLocation } from 'react-router';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


export default function PrimarySearchAppBar() {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  let location = useLocation();
  const [tabVal, setTabVal] = React.useState(false);
  let navigate = useNavigate();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  React.useEffect(() => {
    let page = location.pathname.substring(1);
    page = page.split('&')[0]
    page = page.split('/')[0]
    page = ['lego','projects','contacts'].some(v => page.includes(v))?page:false
    setTabVal(page)
  }, [location.pathname])

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const navigateAndClose = (tab, url) => {
    navigate(url?url:'/'+tab)
    setTabVal(tab)
    handleMobileMenuClose()
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={()=>navigateAndClose('lego','/lego/secondary')}>LEGO Education</MenuItem>
      <MenuItem onClick={()=>navigateAndClose('projects')}>Projekti</MenuItem>
      <MenuItem onClick={()=>navigateAndClose('contacts')}>Kontakti</MenuItem>
    </Menu>
  );

  return (
    <Box >
      <AppBar position="static">
        <Toolbar>
            <IconButton
                component="span"
                onClick={() => {
                navigate("/");
                setTabVal(false);
                handleMobileMenuClose();
                }}
            >
                <img alt="" src="/images/robotiem_logo_simple.png" />
            </IconButton>

            <Tab
                value="home"
                textColor="inherit"
                label="Robotiem.lv"
                onClick={() => {
                navigate("/");
                setTabVal(false);
                handleMobileMenuClose();
                }}
            />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "inline-flex" } }}>
            <Tabs
              value={tabVal}
              onChange={(o, v) => setTabVal(v)}
              textColor="inherit"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                value="lego"
                label="Lego Education"
                onClick={() => navigateAndClose("lego",'/lego/secondary')}
              />
              <Tab
                value="projects"
                label="Projekti"
                onClick={() => navigateAndClose("projects")}
              />
              <Tab
                value="contacts"
                label="Kontakti"
                onClick={() => navigateAndClose("contacts")}
              />
            </Tabs>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}
